.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  font-family: 'SF Pro Text Medium', sans-serif;
  font-size: 17px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s;

  &[disabled] {
    background-color: #ccc !important;

    &:hover {
      background-color: #ccc !important;
    }
  }

  &_dark {
    color: #fff !important;
    background-color: rgb(255,88,97) !important;

    &:hover {
      background-color: rgba(255,73,103,1) !important;
    }
  }

  &_light {
    background-color: #fff !important;
    border: 1px solid rgb(255,88,97) !important;
    color: #1A1A1A;

    &:hover {
      background-color: #f2f2f2 !important;
      border: 1px solid rgba(255,73,103,1);
    }
  }

  &_transparent {
    color: #1A1A1A !important;
    text-decoration: underline !important;
    background-color: transparent !important;

    &:hover {
      color: rgb(255,88,97) !important;
    }
  }
}
