.split{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  text-align: center;
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

.splitTable,
.splitTitle{
  margin-bottom: 10px;
}
.splitText{
  margin-bottom: 24px;
}
.splitBtns{
  width: 100%;
  margin-bottom: 22px;

  > * + * {
    margin-top: 15px;
  }
}

.input{
  margin-top: 18px;
  margin-bottom: 27px;
}

.box{
  margin-top: 22px;
  margin-bottom: 27px;
  padding: 48px 20px 16px 20px;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  text-align: center;
}
.controls{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 38px;
}
.btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.04);
}
.qty{
  width: 70px;
  font-size: 38px;
  line-height: 51px;
  letter-spacing: -0.41px;
  color: #1A1A1A;
  opacity: 0.9;
}

.totalText{
  color: #000;
}

.list{
  margin-top: 22px;
  margin-bottom: 16px;
}
.row{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.item {
  padding-bottom: 10px;
}

.item + .item {
  margin-top: 10px;
}

.item:not(:last-child){
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
}

.checkboxWrapper{
}

.Checkbox {
  :global {
    .rs-checkbox-checker {
      padding: 0;
      min-height: auto;
    }

    .rs-checkbox-wrapper {
      top: 0;
      left: 0;
    }
  }
}

.qty{
  display: flex;
  align-items: center;
  justify-content: center;
}
.qtyText{
  margin-right: 8px;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.9);
}
.info{
  align-items: flex-end;
  margin-top: 8px;
}
.price{
  margin-left: 70px;
  font-size: 17px;
  line-height: 23px;
  color: #1A1A1A;
}
.priceSign{
  font-size: 14px;
  line-height: 19px;
}
.priceBold{
  font-family: 'SF Pro Text Semibold', sans-serif;
}
.total{
  margin-bottom: 22px;
}

.Modal {
  padding: 0 !important;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ModalHeader {
  padding: 20px;
}

.ModalBody {
  overflow: auto;
  padding: 20px;
  flex: 1;
}

.ModalFooter {
  padding: 10px 20px;
  border-top: 1px solid #EBEBEB;
}
