.Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  flex: 1;
}


.Title {
  margin-top: 10px;
}

.Subtitle {
  margin-top: 10px;
}
