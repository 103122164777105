.title {
  margin-top: 5px;
}

.input {
  margin-top: 20px;
}

.inputCombined {
  display: flex;
}

.inputCombined div:first-child {
  margin-right: 7px;
}

.inputCombined div:last-child {
  margin-left: 7px;
}

.btn {
  margin-top: 30px;
}

.oldAmount {
  text-decoration: line-through;
}

.Modal {
  padding: 0 !important;
  height: 100%;
  overflow: hidden;
  max-height: 60vh;
}

.ModalHeader {
  padding: 20px;
}

.ModalBody {
  flex: 1;
  padding: 0 20px;
}

.Iframe {
  width: 100%;
  border: none;
  height: 100%;
}

.Content {
  width: 100%;
  padding-top: 40px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.Bill {

}

.CheckoutForm {
  .CheckoutFormItem + .CheckoutFormItem {
    margin-top: 20px;
  }
}

.StripePayButton {

}

.BillItemsList {
  padding: 0 20px;
  background-color: #F6F6F6;
}

.BillItemsListTop {
  height: 29px;
  background: transparent url('./images/BillTop.svg') center top no-repeat;
  background-size: cover;
  margin-top: 20px;
}

.BillItemsListBottom{
  bottom: -33px;
  height: 33px;
  background: transparent url('./images/BillBottom.svg') center bottom no-repeat;
  background-size: cover;
  margin-bottom: 20px;
}

.Terms {
  text-align: center;

  a {
    color: #fe7b70;
  }
}
