.title{
    font-family: 'SF Pro Text Medium', sans-serif;
    font-size: 22px;
    line-height: 30px;
    color: #1A1A1A;
    display: flex;
    align-items: center;
}
.bolder{
    font-family: 'SF Pro Text Semibold', sans-serif;
}
.small{
    font-size: 17px;
}
