.result{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  justify-content: center;
  flex: 1;
  padding: 0 20px;
}
.img{
  margin-bottom: 23px;
}
.title{
  margin-bottom: 12px;
}
.text{
  margin-bottom: 25px;
  padding: 0 10px;
}
.btns{
  width: 100%;
}

.input{
  margin-top: 19px;
  margin-bottom: 27px;
}
