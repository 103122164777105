.Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  flex: 1;
  padding: 0 20px;
}

.Title {
  margin-top: 24px;
}

.Table {
  margin-top: 50px;
}

.TextsList {
  margin-top: 24px;
}

.TextsListItem + .TextsListItem {
  margin-top: 12px;
}

.MenuButton {
  margin-top: 24px;
}

.Loader {
  margin-top: 12px;
  width: 100%;
}
