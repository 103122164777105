.Header {
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
  min-height: 80px;
  background: rgb(255,73,103);
  background: linear-gradient(180deg, rgba(255,73,103,1) 0%, rgba(255,102,90,1) 100%);
  padding-left: 10px;
  padding-right: 10px;

  .CurrentLanguage {
    padding: 5px;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .Title {
    font-family: 'SF Pro Text Semibold', sans-serif;
    font-size: 17px;
    color: #FFFFFF;
    flex: 1;
    text-align: center;
  }

  .Menu {
    cursor: pointer;
    padding: 10px;

    svg {
      font-size: 24px;
      color: #fff;
    }
  }

  .Back {
    cursor: pointer;
    padding: 10px;

    svg {
      font-size: 24px;
      color: #fff;
    }
  }
}

.Popover {
  margin-top: -10px !important;
  margin-left: -10px;

  .LanguageRow {
    display: flex;

    .LanguageItem + .LanguageItem {
      margin-left: 10px;
    }

    .LanguageItem {
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}
