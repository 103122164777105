.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  flex: 1;
  margin-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;

  .ButtonsList {
    margin-top: 3rem;
    width: 100%;

    > * + * {
      margin-top: 15px;
    }
  }

  .Title {
  }

  .Price {
    margin-top: 1rem;
  }

  .Total {
    margin-top: 1rem;
  }


}
