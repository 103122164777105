.Content{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  flex: 1;
  padding-top: 20px;
  overflow: auto;

  .Image{
    margin-bottom: 23px;
  }

  .Title {
    margin-bottom: 12px;
  }

  .Description {
    margin-bottom: 25px;
    padding: 0 10px;
  }

  .ButtonsList {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    > * + * {
      margin-top: 15px;
    }
  }
}

.Input {
  margin-top: 20px;
}

.SubmitButton {
  margin-top: 10px;
}

