.Invoice {
  .Content {
    padding-top: 50px !important;
  }
}

.Container {
  height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;

  .Body {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -15px;
    background-color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .Avatar {
    position: absolute;
    left: 0;
    margin: 0 auto;
    top: calc(-75px / 2);
    right: 0;
  }

  .Content {
    display: flex;
    align-items: center;
    flex-direction: column;
    //margin-top: calc(75px / 2 + 10px);
    width: 100%;
    padding: 0 20px;

    > * + * {
      margin-top: 10px;
    }

    .Buttons {
      margin-top: 20px;
      width: 100%;

      > * + * {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.TopHorizontal {
  display: flex;
  width: 100%;
  margin-top: 20px;
  padding-left: 20px;

  > * + * {
    margin-left: 20px;
  }
}

.Positions {
  width: 100%;
  padding: 20px;

  .Position {
    display: flex;

    > :first-child {
      flex: 1;
    }

    > * + * {
      margin-left: 20px;
    }
  }

  .Position + .Position {
    margin-top: 20px;
  }
}

.Content {
  padding: 20px;
  margin: 0;
}
