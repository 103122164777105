.wrapper {
  width: 100%;
  margin-top: 40px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.content{
  position: relative;
  margin-bottom: 50px;
  margin-top: 50px;
  padding: 0 20px;
  background-color: #F6F6F6;
}

.item{
  display: flex;
  justify-content: space-between;
}
.item:not(:first-child){
  padding-top: 17px;
  border-top: 1px dashed rgba(0, 0, 0, 0.4);
}
.item:not(:last-child){
  padding-bottom: 18px;
}
.item.final{
  border-bottom: none;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
}
.price{
  margin-left: 40px;
  font-size: 17px;
  line-height: 23px;
  text-align: right;
  letter-spacing: -0.41px;
  color: #1A1A1A;
}
.price span:first-child{
  font-size: 14px;
  line-height: 19px;
}
.methods{
  margin-bottom: 25px;
}

.btns {
  padding-left: 20px;
  padding-right: 20px;

  > * + * {
    margin-top: 15px;
  }
}
