.Modal {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: 90vh;
  padding: 36px 20px;
  border-radius: 15px 15px 0 0;
  background-color: #fff;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.Header {
  display: flex;
  align-items: center;
}

.Title {
  flex: 1;
}

.Overlay {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
}

.CloseButton {
  background: none !important;

  > svg {
    font-size: 30px;
    color: #1A1A1A;
  }
}
