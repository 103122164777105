.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  padding: 20px;

  .Image {
    width: 50%;
    margin: 10px;
  }

  .Text {
    margin-top: 10px;
  }

  .Description {
    margin-top: 10px;
  }

  .ButtonsList {
    width: 100%;
    margin-top: 20px;

    button + button {
      margin-top: 10px;
    }
  }
}
