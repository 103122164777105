.Main {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.Menu {
  display: flex;
  overflow-x: auto;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 80px;
  padding-left: 20px;

  > * + * {
    margin-left: 20px;
  }

  .MenuItem {
    padding: 12px 15px;
    border-radius: 16px;
    background-color: #F4F4F4;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 17px;
    line-height: 1;
    color: rgba(0, 0, 0, 0.9);
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &_selected {
      background-color: #212121;
      color: rgba(255, 255, 255, 0.9);
    }
  }
}

.ProductsList {
  width: 100%;
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;

  .ProductItem {
    cursor: pointer;
    padding: 20px 0;
    color: #1A1A1A;

    &:not(:last-child) {
      border-bottom: 1px dashed rgba(0, 0, 0, 0.4);
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      font-family: 'SF Pro Text Semibold', sans-serif;
      font-size: 17px;
      line-height: 19px;
    }

    .price {
      font-size: 17px;
      line-height: 23px;
    }

    .priceSign {
      font-size: 14px;
      line-height: 19px;
    }

    .text {
      margin-top: 5px;
      font-size: 15px;
      line-height: 17px;
      color: #7F7E7E;
    }

  }
}

.ModalBody {
  padding: 20px 0;
}

.ProductForm {

  .QuantityInputGroup {
    max-width: 120px;

    .QuantityInput {
      input {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
      }

      :global {
        .rs-input-number-btn-group-vertical {
          display: none;
        }
      }
    }
  }

  .QuantitySubmitRow {
    display: flex;

    > * + * {
      margin-left: 15px;
    }
  }

  .SubmitButton {
    flex: 1;
  }

  .RemoveButton {
    width: 50px;
  }
}

.SubmitOrder {
  width: 100%;
  padding: 20px;
  border-top: 1px solid #EBEBEB;
}
