.PoweredBy {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: #fff;
}

.Image {
  width: 64px;
}
