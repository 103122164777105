:root {
  --rs-picker-value: #212121;
  --rs-listbox-option-hover-text: #212121;
  --rs-listbox-option-hover-bg: #fff;
  --rs-listbox-option-selected-text: #212121;
  --rs-listbox-option-selected-bg: rgba(33, 33, 33, 0.1);
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./fonts/SF-Pro-Text/SF-Pro-Text-Regular.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro Text Medium";
  src: url("./fonts/SF-Pro-Text/SF-Pro-Text-Medium.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro Text Semibold";
  src: url("./fonts/SF-Pro-Text/SF-Pro-Text-Semibold.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro Display Semibold";
  src: url("./fonts/SF-Pro-Display/SF-Pro-Display-Semibold.otf") format("opentype");
}

html, body, #root {
  height: 100%;
}

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 10px;
  overflow: hidden;
}

body{
  font-family: 'SF Pro Text', sans-serif;

  max-width: 480px;
  display: flex;
  flex-direction: column;
  position: static;
  background: #f8f8f8;
  margin: 0 auto;
}
*, *:after, *:before{
  box-sizing: border-box;
}
button, input, textarea{
  outline: none;
  padding: 0;
  border: none;
}
.text-primary{
  color: #1A1A1A;
}

body.modal-open {
  overflow: hidden;
}
